const mail = document.body.querySelector('#about #mail');

const email = document.createElement('a');
const decoded = atob('bWFpbHRvOk1hSWwuU2hSRXlBZ0BHbUFpTC5jT20=');
email.textContent = decoded.substring(7);
//email.href = decoded.toLowerCase().replace('@', '+website@');
email.title = "Send E-mail";
email.rel = "foaf:mbox";

mail.prepend(email);
